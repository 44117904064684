<template>
  <div id="calendar">
    <v-data-table
      :headers="headers"
      :items="desserts"
      item-key="name"
      class="elevation-1 table-setting"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <div class="d-flex">
          <div style="width: 50%">
            <v-text-field
              v-model="search"
              label="Search (UPPER CASE ONLY)"
              class="mx-4 search-bar"
              single-line
              hide-details
            >
            </v-text-field>
          </div>
          <!-- Branch dropdown -->
          <div>
            <v-col>
              <v-select
                :items="allBranches"
                item-value="id"
                item-text="id"
                v-model="selected_branch"
                dense
                outlined
                @change="changeBranch()"
                background-color="white"
                class="custom-select"
                style="width: 100%"
              >
              </v-select>
            </v-col>
          </div>
          <!-- End branch dropdown -->

          <div>
            <!-- Date block -->
            <!-- <v-col cols="5" style="text-align: center">
              <v-btn-toggle class="date_button">
                <v-btn
                  color="white"
                  @click="prev"
                  active-class="remove-opacity"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  background-color="white"
                  :class="focus == today ? 'v-btn--active' : ''"
                  text
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn
                  background-color="white"
                  width="215px"
                  text
                  active-class="remove-opacity"
                >
                  {{ top_filter_date }}
                </v-btn>
                <v-btn
                  color="white"
                  @click="next"
                  active-class="remove-opacity"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col> -->
            <!-- End Date block -->
            <v-btn
              color="#101928"
              dark
              class="add-new mt-3"
              @click="new_service()"
            >
              Add new service
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import getTimes from "../helpers/get_times.js";
import CommonApi from "@/services/CommonApi";

export default {
  name: "Services-List",
  data() {
    return {
      search: "",
      calories: "",
      allBranches: [{ id: "All Branch" }],
      selected_branch: "",
      focus: new Date().toISOString().substr(0, 10),
      today: new Date().toISOString().substr(0, 10),
      top_filter_date: moment().format("dddd, D MMMM Y"),
      ready: false,
      getTimes: getTimes(),
      desserts: [
        {
          Service: "Service name",
          Category: "Category",
          Price: "₹25.00",
        },
        {
          Service: "Service name",
          Category: "Category",
          Price: "₹25.00",
        },
        {
          Service: "Service name",
          Category: "Category",
          Price: "₹25.00",
        },
        {
          Service: "Service name",
          Category: "Category",
          Price: "₹25.00",
        },
        {
          Service: "Service name",
          Category: "Category",
          Price: "₹25.00",
        },
        {
          Service: "Service name",
          Category: "Category",
          Price: "₹25.00",
        },
        {
          Service: "Service name",
          Category: "Category",
          Price: "₹25.00",
        },
        {
          Service: "Product Name",
          Category: "Category",
          Price: "₹25.00",
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Service name",
          align: "left",
          sortable: false,
          value: "Service",
        },
        { text: "Category", value: "Category" },
        { text: "Price", value: "Price" },
      ];
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    formatted_blocked_date() {
      return this.blocked_date
        ? moment(this.blocked_date).format("dddd, D MMMM Y")
        : "";
    },
  },
  mounted() {
    this.fetchEvents();
    this.ready = true;
    this.selected_branch = this.allBranches[0];
    this.top_filter_date = moment(this.focus).format("dddd, D MMMM Y");
  },
  methods: {
    new_service() {
      this.$router.push("/new-service");
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    formatDate: function (date) {
      const newDate = new Date(date.days[0].date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        newDate
      );
      let mm = new Intl.DateTimeFormat("en", { month: "long" }).format(newDate);
      let dd = new Intl.DateTimeFormat("en", {
        day: "2-digit",
      }).format(newDate);
      let tt = new Intl.DateTimeFormat("en", {
        weekday: "long",
      }).format(newDate);

      return `${tt} ${dd} ${mm}, ${ye}`;
    },
    setToday() {
      this.focus = new Date().toISOString().substr(0, 10);
      this.top_filter_date = moment(this.focus).format("dddd, D MMMM Y");
    },
    prev() {
      this.top_filter_date = moment(this.top_filter_date)
        .subtract(1, "days")
        .format("dddd, D MMMM Y");
    },
    next() {
      this.top_filter_date = moment(this.top_filter_date)
        .add(1, "days")
        .format("dddd, D MMMM Y");
    },
    changeBranch() {
      this.allCategories = [{ id: "all", employee_name: "All Employees" }];
      this.fetchEvents();
    },
    async fetchEvents() {
      CommonApi.get_data("innosoft_salon.api.get_branch").then((res) => {
        if (res.status_code == 200) {
          this.allBranches.push(...res.data);
        }
      });
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold !important;
}
.v-text-field {
  width: 85%;
}
.search-bar {
  /* margin-left: 600px !important; */
  margin-bottom: 10px;
}
</style>